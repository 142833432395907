window.Yext = window.Yext || {};
import 'babel-polyfill';
import 'script-loader!node_modules/jquery/dist/jquery.min.js';

// uncomment if you need to do expand/collapse:
import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js';

import { Global } from 'js/common/global.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { InitSoy2JS } from 'js/components/Soy2js/Soy2js.js';
InitSoy2JS(window.Yext);
import { GoogleMaps } from 'js/components/Maps/Providers/Google.js';
import { AutocompleteOptions } from 'js/components/Autocomplete/AutocompleteOptions.js';
import { defaultAutocompleteServiceOptions } from 'js/components/Cobalt/Locator/common.js';
import { MaestroOptions } from 'js/components/Locator/Maestro.js';
import { SearchFormOptions } from 'js/components/SearchForm/SearchFormOptions.js';

import { GoogleAdwords } from 'js/components/GoogleAdwords/index.js'; /* TODO: remove if unused */
import { GoogleAnalytics } from 'js/components/GoogleAnalytics/index.js'; /* TODO: remove if unused */
import { LocationHours } from 'js/components/LocationHours/index.js'; /* TODO: remove if unused */
import { Maps } from 'js/components/Maps/Providers/index.js'; /* TODO: remove if unused */

import { handleMap, handleGeolocateButton } from 'js/common/modules/SearchMapUtil.js';
import { LocationMap, MapSetup } from 'js/search/modules/LocationMap.js';
window.Yext.Maps = { SearchMap: LocationMap };
require('script-loader!.tmp/js/templates/common/strings.js');
require('script-loader!.tmp/js/templates/components/Secrets/Secrets.js');

Global.init();
GoogleAdwords.init();
GoogleAnalytics.enableAutotracking('yext');

OnReady(() => {
  Maps.loadAndRun();
  LocationHours.loadAndRun();
  const mapProvider = GoogleMaps;
  mapProvider.load(secrets.YextMapsKeyGoogle(), {
    autocomplete: true,
    client: secrets.YextMapsClientGoogle(),
  }).then(() => {
    window.Yext.Maps.SearchMap.providerCallback()
    MapSetup();
    handleMap('js-directory');
    handleGeolocateButton('.js-directory-geolocateTrigger');
    const formEl = document.getElementById('search-form');
    const inputEl = document.querySelector('.js-locator-input');
    const searchForm = new SearchFormOptions(formEl, inputEl).withExternalInputs([])
      .build();
    const locatorOptions = new MaestroOptions(searchForm).withIsLocator(false);
    const autocomplete = new AutocompleteOptions(searchForm)
      .withAutocompleteServiceOptions(defaultAutocompleteServiceOptions(mapProvider))
      .build()
    locatorOptions.withBeforeSubmit(async () => autocomplete.updateQueryType());
    let maestro = locatorOptions.build();
  });

  // - The un-minified file is different than the minified one :/
  // - Uncomment the following if using JVector map
  // VectorMap.autorun();
});
