class AutocompleteService {
  constructor(options) {
    this.countryForLocale = options.countryForLocale;
    this.countryRestrictions = options.countryRestrictions;
    this.attrEl = options.attrEl;
    this.userLocationInfo = options.userLocationInfo;
  }

  getPredictions(query) {
    throw new Error('Not implemented');
  }

  getPredictionAt(index) {
    throw new Error('Not implemented');
  }
}
export {
  AutocompleteService
};
