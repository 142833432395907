import { AutocompleteService } from './AutocompleteService.js';
import { PredictionBuilder, PredictionType } from './Predictions.js';
import { Coordinate } from 'js/components/Geo/Coordinate.js';

const GooglePlaceTypeToPredictionType = {
  'country': PredictionType.COUNTRY,
  'administrative_area_level_1': PredictionType.REGION,
  'geocode': PredictionType.GEOCODE,
};

const allowedCodes = {
  'US': true,
};

function searchByRegionAllowedIn(countryCode) {
  return allowedCodes[countryCode] || false;
}

class GoogleAutocompleteService extends AutocompleteService {
  constructor(options) {
    super(options);
    this.predictionOptions = {};
    this.types = options.types;
    this.radius = options.radius;
    this.strictBounds = options.strictBounds;
    this.locale = options.locale;
    this.disableRegionSearch = options.disableRegionSearch;

    //Restrict prediction query to these countries if passed in
    this.predictionOptions.componentRestrictions = this.componentRestrictions();

    //Constrain predictions to this radius if passed in
    this.predictionOptions.radius = this.radius || null;

    //Constrain types of autocomplete results returned if passed in. I.E restrict to geocode or establishment
    //https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
    this.predictionOptions.types = this.types || null;

    this.googleAutoCompleteService = new google.maps.places.AutocompleteService();
    this.googlePlacesService = new google.maps.places.PlacesService(this.attrEl);

    // do this here bc of race condition with google library not being available
    this.userLocationInfo.getUserLocation()
      .then((userLocation) => {
        this.predictionOptions.location = new google.maps.LatLng(userLocation.latitude, userLocation.longitude);
      })
      .catch(err => console.log(err));

    // subscribing to updates so we get a better bias if the use the "use my location" button.
    this.userLocationInfo.subscribeToUpdates((userLocation) => {
      this.predictionOptions.location = new google.maps.LatLng(userLocation.latitude, userLocation.longitude);
    });
  }

  componentRestrictions() {
    if (this.countryForLocale && this.countryForLocale[this.locale] && this.countryForLocale[this.locale].length > 0) {
      if (this.countryForLocale[this.locale].length > 5) {
        console.warn(`too many country restrictions, max is 5: [${this.countryForLocale[this.locale].join(', ')}]`);
      }
      return {
        country: this.countryForLocale[this.locale].slice(0,5)
      };
    }

    if (this.countryRestrictions && this.countryRestrictions.length > 0) {
      if (this.countryRestrictions.length > 5) {
        console.warn(`too many country restrictions, max is 5: [${this.countryRestrictions.join(', ')}]`);
      }
      return {
        country:this.countryRestrictions.slice(0,5)
      };
    }
    return null;
  }

  getPredictions(query) {
    return new Promise((resolve, reject) => {
      this.predictionOptions.input = query;
      this.googleAutoCompleteService.getPlacePredictions(this.predictionOptions,(predictions, status) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
          return;
        }
        this.predictions = predictions;
        resolve(this.getshortPredictions(predictions));
      });
    });
  }

  getPredictionAt(index) {
    return new Promise((resolve, reject) => {
      if(!this.predictions) {
        reject('No results');
        return;
      }
      const currentPrediction = this.predictions[index];
      if(!currentPrediction) {
        reject('Index out of bounds');
        return;
      }
      this.googlePlacesService.getDetails({
          fields: [
            'address_component', 'type', 'name', 'geometry'
          ],
          placeId: currentPrediction.place_id
        },
        (place, status) => {
          if (status != google.maps.places.PlacesServiceStatus.OK) {
            reject(status);
            return;
          }
          let predictionType = this.googleTypeToPredictionType(currentPrediction.types);
          let resultBuilder = new PredictionBuilder()
            .withName(currentPrediction.description)
            .withId(currentPrediction.id)
            .withType(predictionType);
          if(predictionType === PredictionType.COUNTRY) {
            resultBuilder.withISOCountryCode(place.address_components[0].short_name);
          }
          else if (predictionType === PredictionType.REGION) {
            let regionCode = place.address_components[0].short_name,
              countryCode = place.address_components[1].short_name;
            // only some regions actually work with Google Autocomplete, fallback to geocode
            if (searchByRegionAllowedIn(countryCode) && !this.disableRegionSearch) {
              resultBuilder.withISORegionCode(regionCode);
              resultBuilder.withISOCountryCode(countryCode);
            } else {
              resultBuilder.withType(PredictionType.GEOCODE);
              resultBuilder.withCoordinate(
                new Coordinate(
                  place.geometry.location.lat(),
                  place.geometry.location.lng()
                ));
            }
          }
          else {
            resultBuilder.withCoordinate(
              new Coordinate(
                place.geometry.location.lat(),
                place.geometry.location.lng())
            );
          }
          resolve(resultBuilder.build());
        });
    });
  }

  googleTypeToPredictionType(googleTypesArray) {
    //prioritize region then country then geocode
    let index = googleTypesArray.indexOf('administrative_area_level_1');
    if (index == -1) {
      index = googleTypesArray.indexOf('country');
    }
    if (index == -1) {
      index = googleTypesArray.indexOf('geocode');
    }
    let type = GooglePlaceTypeToPredictionType[googleTypesArray[index > -1 ? index : 0]] || PredictionType.GEOCODE;
    return type;
  }

  getshortPredictions(predictionsObjArr) {
    return predictionsObjArr.map((predictionsObj) => {
      return new PredictionBuilder()
        .withName(predictionsObj.description)
        .withId(predictionsObj.id)
        .withType(this.googleTypeToPredictionType(predictionsObj.types))
        .withMatchedSubstrings(predictionsObj.matched_substrings)
        .build();
    });
  }
}

export {
  GoogleAutocompleteService
};
