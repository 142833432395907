import { OptimizedResizeInstance } from 'js/components/Util/OptimizedResize.js';
import { isVisible } from 'ts/components/Maps/isVisible.ts';

export class BaseMap {
  static initClass() {
    this.instances = [];
    this.className = "Yext.Maps.Base";
  }
  constructor(args) {
    ({
      config: this.config,
      locs: this.locs,
      nearbyLocs: this.nearbyLocs,
      element: this.element
    } = args);
    if (this.locs == null) { this.locs = []; }
    if (this.nearbyLocs == null) { this.nearbyLocs = []; }
    this.allLocations = this.locs.concat(this.nearbyLocs);
    this.BUFFER = 30; // edge buffer for fitting markers within viewport bounds
    this.mapDimensions = {};

    this.pinWidth = 28;
    this.pinHeight = 38;

    this.latitude = 39.833333;
    this.longitude = -98.583333;

    if (this.config.maxNumberOfLocationsToDisplay != null) {
      this.allLocations = this.allLocations.slice(0, this.config.maxNumberOfLocationsToDisplay);
    }

    this.constructor.instances.push(this);
  }

  static providerCallback() {
    Array.from(this.instances).forEach((instance) => {
      let loadMap = () => {
        instance.map = instance.prepareMap();
        let prepareEvent = null;
        prepareEvent = new CustomEvent('map.prepared', {});
        instance.element.dispatchEvent(prepareEvent);
      }
      if (isVisible(instance.element)) {
        loadMap();
      } else {
        let loadListener = () => {
          if(isVisible(instance.element)) {
            if (!instance.element.classList.contains('js-map-ready')) {
              loadMap();
            }
            OptimizedResizeInstance.remove(loadListener);
          }
        }
        OptimizedResizeInstance.on(loadListener);
      }
    });
  }

  appendProviderScripts() {
    if (!this.constructor.providerLoaded) {
      this.appendScript();
      this.constructor.providerLoaded = true;
      return;
    }
  }
  mapOffset() {
    return {
      top: this.BUFFER,
      right: this.BUFFER,
      bottom: this.BUFFER,
      left: this.BUFFER
    };
  }

  pinClickTrackingEvent() {
    if (this.config.linkToGetDirections) return Yext.Analytics.StandardEvents.DrivingDirections + '_map';
    return 'visit_page';
  }

  clickHandler(location) {
    let tracking = this.pinClickTrackingEvent();
    if (this.config.linkToGetDirections) {
      // Since this should open in a new window, using a callback and a timeout is dangerous
      // as some browsers will think it's a popup, and thus block it. We can just track
      // the event with no callback since the original window remains open
      Yext.Analytics.trackEvent(tracking);
      const newWindow = window.open();
      newWindow.opener = null;
      newWindow.location = location.get_directions_url;
    } else {
      Yext.Analytics.trackEvent(tracking, () => {
        return window.location.href = this.config.baseUrl + location.url;
      });
    }
  }

  svgPin(label, backgroundColor, textColor) {
    if (label == null) { label = '•'; }
    if (backgroundColor == null) { backgroundColor = 'red'; }
    if (textColor == null) { textColor = 'white'; }
    return `
      <svg width='${this.pinWidth}'
           height='${this.pinHeight}'
          viewBox='0 0 ${this.pinWidth} ${this.pinHeight}'
          xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <style type=\"text/css\">
            text {
            font-family: sans-serif;
            font-weight: 400;
            font-size: 15px;
          }
          </style>
        </defs>
        <g fill='none' fill-rule='evenodd'>
        <path d='M14 38s14-16.268 14-24c0-7.732-6.268-14-14-14S0 6.268 0 14s14 24 14 24z'
              fill='#b00c0a' />
          <text fill='${textColor}'>
            <tspan x='${this.pinWidth/2 - 3}'
                   y='${this.pinHeight/2 - 2}'
                   text-anchor='middle'>
                   ${label}
            </tspan>
          </text>
        </g>
    </svg>
`;
  }
  iconImage(location, i) {
    return `data:image/svg+xml;charset=utf8,${encodeURIComponent(this.svgPin())}`;
  }

  mapLoaded() {
    this.element.classList.remove('js-map-not-ready');
    this.element.classList.add('js-map-ready');

    let readyEvent = null;
    readyEvent = new CustomEvent('map-ready', {});
    return this.element.dispatchEvent(readyEvent);
  }

  appendScript() {
    console.error("this method is not implemented");
  }
  preparePin() {
    console.error("this method is not implemented");
  }
  prepareMap() {
    console.error("this method is not implemented");
  }
}

BaseMap.initClass();
