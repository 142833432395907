import { AnalyticsDom } from './AnalyticsDom';
export class Scope extends AnalyticsDom {
    constructor(text = '') {
        super();
        let scope = document.createElement('div');
        scope.classList.add('Scope');
        let content = document.createElement('span');
        content.classList.add('Scope-content');
        content.innerText = text;
        scope.appendChild(content);
        this._domElement = scope;
        this._domElement.style['visibility'] = 'invisible';
    }
}