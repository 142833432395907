import { LocatorEvent, PinClickEvent, CardClickEvent, PinHoverEvent } from './Events.js';
import { SearchMap } from 'js/search/modules/Google.js';
import { GoogleMapsEnterprise } from 'js/components/Maps/Providers/GoogleMapsEnterprise.js';
import { Maps } from 'js/components/Maps/Providers/index.js';

export class LocationMap extends SearchMap {

  static initClass() {
    Maps.FactoryForProvider.Google = (data) => new this(data);
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.SearchMap";
    this.breakPoint = 992;
    this.pinClickCardToTop = true;
    this.pinHoverCardToTop = false;
    this.disablePinHover = false;
  }

  svgPin(label, backgroundColor, textColor) {
    if (label == null) { label = '•'; }
    if (backgroundColor == null) { backgroundColor = 'red'; }
    if (textColor == null) { textColor = 'white'; }
    return `
      <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='35' viewBox='0 0 32 42'>
          <defs>
              <path id='b' d='M17.97 27.817c-3.256 6.006-1.355 14.074-1.355 14.074s10.528-7.85 13.784-13.857c2.441-4.505 1.63-8.133-1.832-8.194-3.463-.06-8.156 3.472-10.597 7.977z'/>
              <path id='c' d='M0 15.4C0 26.84 16 42 16 42s16-15.16 16-26.6C32 6.82 24.914 0 16 0S0 6.82 0 15.4z'/>
          </defs>
          <g fill='none' fill-rule='evenodd'>
              <use fill='#D53A34' xlink:href='#c'/>
              <path stroke='#e68985' stroke-width='2' d='M16 40.6a85.061 85.061 0 0 1-7.206-8.218c-2.743-3.58-4.9-7.066-6.252-10.315C1.53 19.634 1 17.397 1 15.4 1 7.381 7.63 1 16 1c8.37 0 15 6.381 15 14.4 0 1.997-.53 4.234-1.542 6.667-1.351 3.249-3.51 6.736-6.252 10.315A85.061 85.061 0 0 1 16 40.6z'/>
              <text fill='#FFF' font-family='Arial' font-size='14' font-weight='500'>
              </text>
          </g>
      </svg>
    `;
  }

  iconImage(location, i) {
    const iconUrl = `data:image/svg+xml;charset=utf8,${encodeURIComponent(this.svgPin(i))}`;
    return {
      url: iconUrl,
      scaledSize: new google.maps.Size(26, 35)
    };
  }

  clickHandler(url, baseUrl) {
    let href = '';
    let matches = url.url.split('/');
    if (matches.length <= 2) {
      return window.location.href = url.url
    } else {
      return window.location.href = matches.slice(1).join('/');
    }
  }

}
LocationMap.initClass();

export function MapSetup() {}
