import { LocatorEvent, PinClickEvent, CardClickEvent, PinHoverEvent } from './Events.js';
import { GoogleMapsEnterprise } from 'js/components/Maps/Providers/GoogleMapsEnterprise.js';
import { Maps } from 'js/components/Maps/Providers/index.js';

export class SearchMap extends GoogleMapsEnterprise {
  static initClass() {
    Maps.FactoryForProvider.Google = (data) => new this(data);
    this.instances = [];
    this.providerLoaded = false;
    this.className = "Yext.Maps.SearchMap";
    this.breakPoint = 992;
    this.pinClickCardToTop = true;
    this.pinHoverCardToTop = false;
    this.disablePinHover = false;
  }

  mapOffset() {
    let left = 0;
    let searchcontent = document.querySelector('.search-content-container');
    if ((searchcontent != null) && (window.innerWidth >= this.constructor.breakPoint)) {
      left = searchcontent.offsetLeft + searchcontent.offsetWidth;
    }
    return {
      top: this.BUFFER,
      bottom: this.BUFFER,
      left,
      right: this.BUFFER
    };
  }

  svgPin(label, backgroundColor, textColor) {
    if (label == null) { label = '•'; }
    if (backgroundColor == null) { backgroundColor = '#D53A34'; }
    if (textColor == null) { textColor = '#FFF'; }
    return `
      <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='35' viewBox='0 0 32 42'>
          <defs>
              <path id='b' d='M17.97 27.817c-3.256 6.006-1.355 14.074-1.355 14.074s10.528-7.85 13.784-13.857c2.441-4.505 1.63-8.133-1.832-8.194-3.463-.06-8.156 3.472-10.597 7.977z'/>
              <path id='c' d='M0 15.4C0 26.84 16 42 16 42s16-15.16 16-26.6C32 6.82 24.914 0 16 0S0 6.82 0 15.4z'/>
          </defs>
          <g fill='none' fill-rule='evenodd'>
              <use fill='${backgroundColor}' xlink:href='#c'/>
              <path stroke='#e68985' stroke-width='2' d='M16 40.6a85.061 85.061 0 0 1-7.206-8.218c-2.743-3.58-4.9-7.066-6.252-10.315C1.53 19.634 1 17.397 1 15.4 1 7.381 7.63 1 16 1c8.37 0 15 6.381 15 14.4 0 1.997-.53 4.234-1.542 6.667-1.351 3.249-3.51 6.736-6.252 10.315A85.061 85.061 0 0 1 16 40.6z'/>
              <text fill='${textColor}' font-family='Arial' font-size='14' font-weight='500'>
                <tspan x='${this.pinWidth/2 - 1}'
                       y='${this.pinHeight/2 + 2}'
                       text-anchor='middle'>${label}
                </tspan>
              </text>
          </g>
      </svg>
    `;
  }

  preparePin(i, loc, m) {
    let pin = super.preparePin(...arguments);
    pin.isDefault = true;

    if (!this.constructor.disablePinHover) {
      pin.addListener('mouseover', () => {
        this.hoverHandler(true, loc, pin, i, m);
      });
      pin.addListener('mouseout', () => {
        this.hoverHandler(false, loc, pin, i, m);
      })
    }

    let changeColor = (needsHighlight) => {
      let hasHighlight = !pin.isDefault;
      if (needsHighlight && !hasHighlight) {
        pin.setIcon(this.iconImage(loc, i, 'white', 'black'));
        pin.isDefault = false;
        pin.setZIndex(1);
      } else if (!needsHighlight && hasHighlight) {
        pin.setIcon(this.iconImage(loc, i));
        pin.isDefault = true;
        pin.setZIndex(0);
      }
      else {}
        //do nothing
    };

    let needsHighlight = (e, type) => {
      let basicNeeds = e.detail.yextId === `js-yl-${loc.id}`;
      if (type !== 'hover') {
        return basicNeeds;
      }
      return basicNeeds && e.detail.active;
    }

    document.addEventListener(CardClickEvent.eventTypeName, (event) => {
      changeColor(needsHighlight(event));
    });

    document.addEventListener(PinClickEvent.eventTypeName, (event) => {
      changeColor(needsHighlight(event));
    });
    document.addEventListener(PinHoverEvent.eventTypeName, (event) => {
      changeColor(needsHighlight(event, 'hover'));
    });

    return pin;
  }

  clickHandler(loc, pin, index, map) {
    let target = `js-yl-${loc.id}`;
    document.dispatchEvent(new PinClickEvent(target));

    if (this.infowindow != null) {
      this.infowindow.close();
      this.infowindow = null;
    }

    let infoContentTemplate = document.querySelector(`#${target}`);
    if (infoContentTemplate == null) { return; }
    if (window.innerWidth >= this.constructor.breakPoint) {
      this.infowindow = new google.maps.InfoWindow({
        content: infoContentTemplate.innerHTML
      });
      this.infowindow.open(map, pin);
    } else {
      this.infowindow = new google.maps.InfoWindow({
        content: infoContentTemplate.innerHTML
      });
      this.infowindow.open(map, pin);
      map.panTo(pin.position);
    }
  }

  hoverHandler(active, loc, pin, index, map) {
    let target = `js-yl-${loc.id}`;
    document.dispatchEvent(new PinHoverEvent(target, active));
  }

  iconImage(loc, i, backgroundColor, textColor) {
    if (backgroundColor == null) { backgroundColor = 'black'; }
    if (textColor == null) { textColor = 'white'; }
    let iconUrl = `data:image/svg+xml;charset=utf8,${encodeURIComponent(this.svgPin(i+1, backgroundColor, textColor))}`;
    return {
      url: iconUrl,
      scaledSize: new google.maps.Size(28,38)
    };
  }
}
SearchMap.initClass();

export function MapSetup() {}
