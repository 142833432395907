
$(".navbar-toggler").click(function(){
    $(".header-m-nav").toggleClass("hidden");
    $("body").toggleClass("hide-overflow");
});

$(window).on('resize', function() {
  if ($(window).width() > 767) {
    $(".header-m-nav").addClass("hidden");
    $("body").removeClass("hide-overflow");
  }
}).trigger('resize');
