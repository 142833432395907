import { FilterModal } from 'js/components/Cobalt/Locator/FilterModal.js';

export function handleMap(mapId) {
  const hideMapArr = document.querySelectorAll('.js-hide-map');
  const showMapArr = document.querySelectorAll('.js-show-map');
  const el = document.getElementById(mapId);

  if (el && hideMapArr && showMapArr) {
    hideMapArr.forEach((element) => {
      element.addEventListener('click', () => {
        el.classList.remove('mobile-map-visible')
      })
    })

    showMapArr.forEach((element) => {
      element.addEventListener('click', () => {
        el.classList.add('mobile-map-visible')
      })
    })
  }
}

export function handleFilters() {
  const filtersEl = document.getElementById('filters')
  const filterModalOpenEl = document.getElementById('js-filters-open');
  const filterModal = new FilterModal(filtersEl, filterModalOpenEl);
  filterModal.updateFilterCount.bind(filterModal);
}

export function handleFilterCount() {
  const fullClearButton = document.querySelector('.js-filters-clearAll');
  const filterRevealEl = document.querySelector('.js-filters-label');

  const baseUrl = window.location.href.split('?')[0];
  const paramStr = false;
  const queryParams = [];
  let numFilters = 0;
  const nonFilterParams = [];

  if (window.location.href.split('?').length > 1) {
    const paramStr = window.location.href.split('?')[1];
    const paramArr = paramStr.split('&');
    paramArr.forEach((param) => {
      queryParams.push(param);
    })
  }
  for (let p of queryParams) {
    if (p.includes('services')) {
      numFilters += 1;
    } else {
      nonFilterParams.push(p);
    }
  }

  let params = '?';
  nonFilterParams.forEach((paramArr, index) => {
    if(index != 0) {
      params += '&';
    }
    params += paramArr;
  })

  const fullUrl = baseUrl + params;

  if (filterRevealEl && numFilters > 0) {
    const labelText = numFilters > 1 ? ' Filters Applied' : ' Filter Applied';
    filterRevealEl.innerHTML = numFilters + labelText;
    filterRevealEl.classList.remove('filter-hidden');
    if (fullClearButton) {
      fullClearButton.classList.remove('filter-hidden');
    }
  } else {
    filterRevealEl.classList.add('filter-hidden');
    if (fullClearButton) {
      fullClearButton.classList.add('filter-hidden');
    }
  }

  if (fullClearButton) {
    fullClearButton.addEventListener('click', () => {
      if (nonFilterParams.length > 0) {
        document.location.href = baseUrl;
      } else {
        document.location.href = fullUrl;
      }
    })
  }
}

export function handleGeolocateButton(buttonSelector) {
  const geolocateButton = document.querySelector(buttonSelector);
  if (geolocateButton) {
    geolocateButton.addEventListener('click', () => {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("g", "true");
      window.location.href = "search.html?" + searchParams.toString();
    });
  }
}
