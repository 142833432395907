export class AnalyticsDom {
    position(top = 'auto', left = 'auto', bottom = 'auto', right = 'auto') {
        this._domElement.style['top'] = top;
        this._domElement.style['left'] = left;
        this._domElement.style['bottom'] = bottom;
        this._domElement.style['right'] = right;
    }
    appendTo(domElement = document.body) {
        domElement.appendChild(this._domElement);
        this._domElement.style['visibility'] = 'visible';
    }
    getHeight() {
        return this._domElement.getBoundingClientRect().height;
    }
    getWidth() {
        return this._domElement.getBoundingClientRect().width;
    }
    getLeft() {
        const left = this._domElement.style['left'];
        return parseInt(left, 10);
    }
    getTop() {
        const top = this._domElement.style['top'];
        return parseInt(top, 10);
    }
    remove() {
        this._domElement.remove();
    }
}