import { MonitoringInit } from 'js/components/Monitoring/Monitoring.js';
import { Polyfills } from 'js/components/Polyfills/Polyfills.js';
import { Analytics } from 'js/components/YextAnalytics/Analytics.js';
import { AccessibilityChecks } from 'js/components/Util/Accessibility.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { ImageObjectFit } from 'js/components/Image/index.js';
import 'js/common/modules/Header.js';
import LazyLoad from 'node_modules/vanilla-lazyload/dist/lazyload.min.js';
import 'script-loader!node_modules/svg4everybody/dist/svg4everybody.min.js';
import { Debug } from 'js/components/Util/Debug.js'
import { AnalyticsDebugger } from "js/components/AnalyticsDebugger/AnalyticsDebugger.js";

export class Global {
  static init() {
    Polyfills.init();
    MonitoringInit();

    OnReady(() => {
      Yext.Analytics = new Analytics(window);

      const thisYear = new Date().getFullYear();
      const dateSpans = document.querySelectorAll('.c-copy-date');
      for (const dateSpan of dateSpans) {
        dateSpan.innerHTML = `&copy; ${thisYear}`;
      }

      Yext.LazyLoader = new LazyLoad({
        elements_selector: '.js-lazy'
      });

      const homeMarket3 = document.querySelectorAll('.js-homeMarket3');
      if (homeMarket3 && homeMarket3.length && window.pageDataTracker){
        homeMarket3.forEach((i) => {
          i.addEventListener('click', window.pageDataTracker.trackAnalyticsEvent("plcccreditupsellviews",{"plccBanner":"<static_storelocator_apply>"}));
        })
      }

      if (Debug.isEnabled()) {
        window.Debugger = new AnalyticsDebugger();
      }

      ImageObjectFit();
      svg4everybody();

      if (Yext.isStaging ||
          window.location.href.includes('yextpages') ||
          window.location.href.includes('localhost')) {
        AccessibilityChecks.checkAltTags();
      }

      let collapseMapButton = $('#collapse-map-button');
      let collapseMapDiv = $('#collapse-map-div');
      collapseMapButton.click(function() {
        if (collapseMapDiv.hasClass('map-init')) {
          collapseMapDiv.removeClass('map-init');
          collapseMapDiv.addClass('map-gone')
          collapseMapDiv.slideDown();
        } else {
          collapseMapDiv.slideToggle();
        }
        collapseMapButton.toggleClass('is-collapsed');
      });

      const generateSearchUrl = keyword => `http://www.jcpenney.com/s/${keyword.replace(RegExp(' ', 'g'), '-')}?Ntt=${keyword.replace(RegExp(' ', 'g'), '+')}`;

      $('#js-keywordSearch').on('submit', function(event) {
        event.preventDefault();
        const keyword = $('#input').val();
        return window.location.href = generateSearchUrl(keyword);
      });

      $('#js-Header-open').on('click', function() {
        $('#main').addClass('isOpen');
      });

      $('#js-Header-close').on('click', function() {
        $('#main').removeClass('isOpen');
      });
    });
  }
}
